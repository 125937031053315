
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

require('../../node_modules/startbootstrap-sb-admin-2/vendor/bootstrap/js/bootstrap.js');
require('../../node_modules/startbootstrap-sb-admin-2/js/sb-admin-2.js');
require('../../node_modules/startbootstrap-sb-admin-2/vendor/jquery-easing/jquery.easing.js');
require('../../node_modules/startbootstrap-sb-admin-2/vendor/chart.js/Chart.bundle.js');

require('../../node_modules/startbootstrap-sb-admin-2/vendor/datatables/jquery.dataTables.min.js');
require('../../node_modules/startbootstrap-sb-admin-2/vendor/datatables/dataTables.bootstrap4.min.js');

require('../../node_modules/select2/dist/js/select2.min');

require('../shared/redactor/redactor.js');
require('../shared/redactor/plugins/filemanager.js');
require('../shared/redactor/plugins/alignment.js');
require('../shared/redactor/plugins/fontcolor.js');
require('../shared/redactor/plugins/fontfamily.js');
require('../shared/redactor/plugins/fontsize.js');
require('../shared/redactor/plugins/fullscreen.js');
require('../shared/redactor/plugins/imagemanager.js');
require('../shared/redactor/plugins/inlinestyle.js');
require('../shared/redactor/plugins/source.js');
require('../shared/redactor/plugins/table.js');

document.addEventListener('DOMContentLoaded', event => {
    const token = $('meta[name=csrf-token]').attr('content');

    $('.redactor').each(function() {
        let buttonArray = ['html', 'format', 'bold', 'italic', 'deleted', 'lists', 'image', 'file', 'link'];

        if ($(this).data('no-image')) {
            buttonArray.splice($.inArray('image', buttonArray), 1);
        }

        $(this).redactor({
            imageUpload: $(this).data('redactorimageupload') + '?_token=' + token,
            imageManagerJson: $(this).data('redactorimagemanagerjson'),
            imageResizable: true,
            imagePosition: true,
            plugins: ['imagemanager', 'alignment', 'inlinestyle', 'table', 'fontsize', 'fontcolor', 'fontfamily', 'fullscreen', 'source'],
            buttons: buttonArray,
            minHeight: '300px'
        });
    });
});
